.app {
    width: 100%;
}

.columns {
    display: flex;
}

.column {
    margin: calc(var(--grid) / 2);
    border: var(--borderWidth) solid #0ff0fc;
    border-radius: var(--borderRadius);
    background-color: whitesmoke;
}

.column-title {
    font-size: 15px;
    color: #3c3c3c;
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.5);
    text-align: center;
    margin: 0;
}

.column-title:hover {
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.8);
}

.task-list {
    /* this has no visible impact, but is in line with the absolute position spacing.
    doing this ensures that dropping into an empty list will be in the correct visual position */
    padding-left: var(--grid);
    padding-right: var(--grid);
    scrollbar-color: dark;
}

.task-list::-webkit-scrollbar {
    width: 1em;
}

.task-list::-webkit-scrollbar-track {
    background-color: var(--greyLight);
    borderradius: var(--borderRadius);
}

.task-list::-webkit-scrollbar-thumb {
    background-color: var(--pink);
}

.item {
    background: white;
    border: var(--borderWidth) solid mediumpurple;
    box-sizing: border-box;
    border-radius: var(--borderRadius);
    color: #3c3c3c;
    font-size: 14px;
    user-select: none;
    font-weight: bold;

    /* center align text */
    display: flex;
    justify-content: left;
    align-items: center;
}

.item.is-dragging {
    background: #515b7d;
    border-color: #3c3c3c;
    box-shadow: 0px 0px 2px #3c3c3c, 0px 0px 10px #3c3c3c;
}
