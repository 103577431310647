
.error-wrap {
    background: #f3f4f6;
    width: 100%;
    height: 100vh;
}

.error-container {
    max-width: 500px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
}

.error-container img {
    height: 150px;
}

@media only screen and (min-width: 768px){
    .error-container img {
        height: 200px;
    }
}

@media only screen and (min-width: 992px){
    .error-container img {
        height: 300px;
    }
}

.error-content {
    margin-top: 20px;
}

@media only screen and (min-width: 768px){
    .error-content {
        margin-top: 500px;
    }
}

@media only screen and (min-width: 992px){
    .error-content {
        margin-top: 110px;
    }
}


.error-content h1 {
    font-size: 20px;
    font-weight: bold;
    text-transform: inherit;
    margin-bottom: 16px;
}

@media only screen and (min-width: 768px){
    .error-content h1 {
        font-size: 24px;
        margin-bottom: 32px;
    }
}

@media only screen and (min-width: 992px){
    .error-content h1 {
        font-size: 28px;
    }
}

.error-content p {
    margin: 0;
    font-size: 10px;
    line-height: 18px;
}

@media only screen and (min-width: 768px){
    .error-content p {
        font-size: 16px;
        line-height: 30px;
    }
}


.error-link {
    margin-top: 16px;
}

@media only screen and (min-width: 768px){
    .error-link {
        margin-top: 32px;
    }
}

.error-link a {
    display: inline-block;
    background: #0b5fff;
    padding: 8px 20px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    transition: all 0.35s ease-in-out;
}

.error-link a:hover,
.error-link a:focus {
    background: #3178ff;
    transition: all 0.35s ease-in-out;
}

.app {
    width: 100%;
}

.columns {
    display: flex;
}

.column {
    margin: calc(var(--grid) / 2);
    border: var(--borderWidth) solid #0ff0fc;
    border-radius: var(--borderRadius);
    background-color: whitesmoke;
}

.column-title {
    font-size: 15px;
    color: #3c3c3c;
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.5);
    text-align: center;
    margin: 0;
}

.column-title:hover {
    text-shadow: 0px 0px 2px var(--pinkDark),
    0px 0px 15px rgba(255, 110, 255, 0.8);
}

.task-list {
    /* this has no visible impact, but is in line with the absolute position spacing.
    doing this ensures that dropping into an empty list will be in the correct visual position */
    padding-left: var(--grid);
    padding-right: var(--grid);
    scrollbar-color: dark;
}

.task-list::-webkit-scrollbar {
    width: 1em;
}

.task-list::-webkit-scrollbar-track {
    background-color: var(--greyLight);
    borderradius: var(--borderRadius);
}

.task-list::-webkit-scrollbar-thumb {
    background-color: var(--pink);
}

.item {
    background: white;
    border: var(--borderWidth) solid mediumpurple;
    box-sizing: border-box;
    border-radius: var(--borderRadius);
    color: #3c3c3c;
    font-size: 14px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-weight: bold;

    /* center align text */
    display: flex;
    justify-content: left;
    align-items: center;
}

.item.is-dragging {
    background: #515b7d;
    border-color: #3c3c3c;
    box-shadow: 0px 0px 2px #3c3c3c, 0px 0px 10px #3c3c3c;
}

